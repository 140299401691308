<template>
    <div id="box">
        <Header :headerIndex="0"></Header>
        <div class="container">
            <div class="index">
                <swiper :options="swiperOption" ref="mySwiper" class="m1440">
                    <swiper-slide v-for="item in banner"><img :src="item.image_text" alt="" class="banner m1440" @click="gourl(item)"></swiper-slide>
                </swiper>
                <div class="" v-if="user">
                    <div class="m1340">
                        <div class="info_box flex-box mtb20" >
                            <div class="flex-box info">

                                <img :src="user.avatar ? user.avatar : require('../assets/static/icon/avatar.png')"
                                    class="avatar" alt="" />
                                <div class="pl40">
                                    <div class=" flex-box fs24" :class="user.is_special==1?'name':''">{{ user.idnum }}</div>
                                    <div class="id fs18 pt20">ID: {{ user.id }}</div>
                                </div>
                            </div>
                            <div class="inventory flex-grow-1">
                                <div class="fs14 col5 pb15">YOUR INVENTORY</div>
                                <div class="flex-box flex-between">
                                    <div>
                                        <span class="fs18">{{ user.coins | numberToCurrency }}</span>
                                        M-Coins
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.rp | numberToCurrency }}</span>
                                        RP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.pp | numberToCurrency }}</span>
                                        PP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.ap | numberToCurrency }}</span>
                                        AP
                                    </div>
                                    <div>
                                        <span class="fs18">{{ user.ep | numberToCurrency }}</span>
                                        EP
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link class="btn more" to="/market" >MORE+</router-link> -->
                    </div>
                </div>
                <div class="part2">
                    <div class="m1440 index_blog">
                        <div class="title tc">&nbsp;2025 展望未来 拥抱美好&nbsp;</div>
                        <div class="introduction tc" style="text-align: left;line-height: 36px;font-size:16px;width:680px;padding-top:20px;">

                            新年伊始万象更新<br/>
                            金蛇银舞 蛇年大吉<br/><br/>
                            
                            SMA 走过了稳定增长的一年<br/>
                            这一年有收获也有挑战<br/>
                            从2个国家（中国大陆 新加坡）4个城市（中国徐州 中国滁州 中国苏州 中国无锡）<br/>
                            到今天的10个城市4个国家<br/><br/>
                            
                            300万原始发行 四次拆分<br/>
                            所有相信的伙伴都拿到自己的结果<br/>
                            感恩所有的SMA 伙伴<br/>
                            因为有你们2025<br/>
                            必将越来越好<br/><br/>
                            
                            每天的进步<br/>
                            都是伙伴们全力以赴的努力！百分百的相信<br/>
                            2025目标 新增10个城市<br/>
                            全球20个城市1 亿美金<br/>
                            2025 携手并进 拿到结果<br/>
                            我们在吉隆坡年会（500人）见<br/><br/>
                            
                            SMA 全球执行委员会<br/>
                            01.01.2025
                        </div>
                    </div>
                </div>
                <div class="part2">
                    <div class="m1440 index_blog">
                        <div class="title tc">FAQ&BLOG</div>
                        <div class="introduction tc">Description of common problems. Description of common problems. </div>
                        <div class="flex-box flex-between faq_list tc">
                            <template v-for="(item, index) in articlelist">
                                <a v-if="!item.url" href="javascript:;" @click="godetail(item)" class="faq">
                                    <img :src="item.image_text" class="pics" alt="">
                                    <div class="fs24">{{ item.name }}</div>
                                </a>
                                <a v-else :href="item.url" class="faq">
                                    <img :src="item.image_text" class="pics" alt="">
                                    <div class="fs24">{{ item.name }}</div>
                                </a>
                            </template>
                            <!-- <a href="javascript:;" class="faq">
                                <img src="../assets/static/icon/pic2.png" class="pics" alt="">
                                <div class="fs24">FDISCOVERY</div>
                            </a>
                            <a href="javascript:;" class="faq">
                                <img src="../assets/static/icon/pic3.png" class="pics" alt="">
                                <div class="fs24">BLOG</div>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import cookie from "vue-cookies";
import { numberToCurrency } from "../assets/filters";
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import "swiper/dist/css/swiper.css";
import "swiper/swiper-bundle.css";

export default {
    filters: {
        numberToCurrency
    },
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            price: '',
            articlelist: '',
            user: '',
            configs: '',
            swiperOption: {
                loop: true,
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                // 显示分页
            },
            banner:''
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
    mounted() {
        // current swiper instance
        // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
        console.log("this is current swiper instance object", this.swiper);
        // this.swiper.slideTo(3, 1000, false);
    },
    created() {
        this.article()
        this.getuser()
        this.config()

        this.getbanner()
    },
    methods: {
        gourl(item){
            if(item.url){
                window.location.href = item.url;

            }
        },
        getbanner(){
            this.$api.getbanner({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.banner = res.data
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })

                }
            })
        },
        config() {
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.configs = res.data
                } else {
                    // that.$message.error(res.msg);

                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        article() {
            this.$api.apiArticle({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articlelist = res.data
                    console.log(this.articlelist)
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
                        message: res.msg,
                        type: 'error',
                        offset: window.screen.height / 2
                    })
                }
            })
        },
        getuser() {
            if (cookie.get('sma_token')) {
                this.$api.apiUserInfo({}).then(ret => {
                    if (ret.code == 1) {

                        this.user = ret.data.userinfo;

                    }
                })
            }


        },
        godetail(item) {
            // this.$router.push({ path: '/articledetail', query: { id: item.id } })
        }
    },
};
</script>
